import React from 'react';
import Header from './Header';
import Decklist from './DeckList';
import Cards from './Cards';
import { CardProvider } from './CardContext';

import './App.css';

function App() {
  return (
    <CardProvider>
        <div className="app">
          <Header />
          <Decklist />
          <Cards />
        </div>
    </CardProvider>
  );
}
export default App;
