import React, { useContext } from 'react';
import ToggleSwitch from '../src/Controls/ToggleSwitch'
import { CardContext } from './CardContext'
import './Settings.css';

function Settings() {
    const { prefersPromo, setPrefersPromo, prefersNonPromo, setPrefersNonPromo, prefersNewest, setPrefersNewest, cropBorders, setCropBorders} = useContext(CardContext)

    return(
        <div className="settingsContainer">
            <div className="promosToggle">
                <label className="toggleLabel">Prefer Promo </label>
                <ToggleSwitch
                    id="promo"
                    small
                    checked={prefersPromo}
                    onChange={ newVal => {
                        if(newVal) {
                            setPrefersNonPromo(false);
                        }
                        setPrefersPromo(newVal)
                        }
                    }/>
            </div>
            <div className="promosToggle">
                <label className="toggleLabel">Prefer Non Promo</label>
                <ToggleSwitch
                    id="nonPromo"
                    small
                    checked={prefersNonPromo}
                    onChange={ newVal => {
                        if(newVal) {
                            setPrefersPromo(false);
                        }
                        setPrefersNonPromo(newVal)
                        }
                    }/>
            </div>
            <div className="promosToggle">
                <label className="toggleLabel">Prefer Newer</label>
                <ToggleSwitch
                    id="newest"
                    small
                    checked={prefersNewest}
                    onChange={setPrefersNewest}/>
            </div>
            <div className="promosToggle">
                <label className="toggleLabel">Crop Borders</label>
                <ToggleSwitch
                    id="clip"
                    small
                    checked={cropBorders}
                    onChange={setCropBorders}/>
            </div>
        </div>
    );
}

export default Settings;