async function findCard(cardName, preferPromo, preferNonPromo, prefersNewest) {
    if (typeof(cardName) !== 'string') {
        return;
    }

  const url = encodeURI('https://api.scryfall.com/cards/search?&q=' + cardName + '&unique=prints&order=released&dir=' + (prefersNewest ? 'desc' : 'asc'));
  const headers = { 'Origin': 'http://localhost:3000' };
  const list = await fetch(url,  { method: 'GET', headers: headers })
  .then(res => res.json())
  .catch(error => console.error(error))

  if (list?.object !== 'list') {
    return null;
  }
  else if (list.total_cards === 0) {
    return null;
  }
  else if (list.total_cards === 1) {
    return list.data[0];
  }
  else {
    let matches = list.data.filter(card => 
      card.name.toUpperCase() === cardName.toUpperCase()
    );
    if (preferPromo) {
      const promos = matches.filter(card => card.promo)
      if (promos.length > 0) {
        return promos[0];
      }
    }
    else if (preferNonPromo) {
      const promos = matches.filter(card => !card.promo)
      if (promos.length > 0) {
        return promos[0];
      }
    }
    if (matches.length > 0) {
      return matches[0];
    }
    else {
      return list.data[0];
    }
  }
}

export default findCard;