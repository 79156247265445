import React, { useContext } from 'react';
import { CardContext } from './CardContext';
import './Header.css';

function Header() {
    const { isLoading, errors } = useContext(CardContext)
    return(
        <div className="header">
          <header className="appHeader">
              Print Magic Cards
          </header>
          {errors.length === 0 ?
          <p> {isLoading ? 
          "Loading..." :
          "Enter Your Decklist"}</p> : null}
        </div>
    )
}

export default Header;