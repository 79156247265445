import React, { useState, useContext } from 'react';
import { CardContext } from './CardContext'
import Settings from './Settings'

import './Decklist.css';

function DeckList() {
    
    const { findCards, isLoading, errors, setErrors} = useContext(CardContext)
    const [localDeckList, setLocalDecklist] = useState('');

    async function onSubmit() {
        if (!localDeckList) {
          setErrors(['Enter a decklist to begin'])
          return;
        }
        findCards(localDeckList);
    }

    return (
        <div className="inputContainer">
            {errors.length > 0 ?
                errors.map((error, i) => {
                    return(
                        <div className="errorBanner" key={"error " + i}>
                            <button className="translucentButton" onClick={ () => {
                                //can't beleive this is the best way to remove an item at index in js
                                setErrors(errors.slice(0, i).concat(errors.slice(i + 1, errors.length)));
                            }}>X</button>
                        <div className="errorMessage">{ error }</div>
                    <div/>
                </div>
               )
           })
           
           : null
            }
            { !isLoading ?
                <div className="inputContainer">
                    <textarea
                        placeholder="Enter your decklist here in the following format:&#10;1 Opt&#10;1 Remand&#10;1 Reverberate&#10;..."
                        onChange={e => {
                            setErrors([])
                            setLocalDecklist(e.target.value)
                            }
                        }
                        value={localDeckList}
                    />
                    <Settings />
                    <button className="submitButton" onClick={ () => {
                            onSubmit()}}>Submit
                    </button>
                </div> : null
            }
        </div>
        );
    }

export default DeckList;