import React, { useContext } from 'react';
import './Cards.css';
import { CardContext } from './CardContext';

const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

function Cards() {
    const { foundCards, isLoading } = useContext(CardContext)
    
    const images = foundCards
    .filter(card => card.images?.length > 0)
    .map(card => {
        var temp = [];
        for (var index = 0; index < card.quantity; index++) {
            temp.push(card.images)
        }
        return temp.flat();
    }).flat();
    
    const groupedCards =  chunk(images, 9);

    return (
        <div className="cardPreview">
            { groupedCards.map((images, index) => {
                return(
                    isLoading ? null :
                <div className="cardGroup" key={index}>
                    { images.map((image, imageIndex) => {
                        return(
                            <div className="cardContainer" key={imageIndex}>
                                <img src={image} alt={image} />
                            </div>
                        )
                    })
                    }
                </div>)
            })
            }
        </div>)
}

export default Cards;
