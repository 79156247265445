import React, { useState } from 'react'
import findCard from './scryfall'

export const CardContext = React.createContext();
const ignored = ["sideboard", 'maybeboard', ''];

export const CardProvider = (props) => {
  const [deckList, setDeckList] = useState("opt")
  const [errors, setErrors] = useState([]);  
  const [foundCards, setFoundCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prefersPromo, setPrefersPromo] = useState(false);
  const [prefersNonPromo, setPrefersNonPromo] = useState(false);
  const [prefersNewest, setPrefersNewest] = useState(true);
  const [cropBorders, setCropBorders] = useState(false);

  async function findCards(deckList) {
    setIsLoading(true);
    const regex = /^([0-9]*)[\s]*([\w ',+]+)*$/gm;
    var cards = [];
    let m;

    while ((m = regex.exec(deckList)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
          regex.lastIndex++;
      }
      if (!ignored.includes(m[0]?.trim().toLowerCase())) {
        cards.push({
          searchText: m[0]?.trim(),
          quantity: m[1] ? m[1] : 1,
          name: m[2]?.trim(),
          images: null
        });
      }
    }

    var errors = [];
    await Promise.all(cards.map(async (c, index) => {
      const card = await findCard(c.name, prefersPromo, prefersNonPromo, prefersNewest, cropBorders)
      if (card == null) {
        errors.push(c.searchText + ' could not be found \r\n');
      }
      else if (card["object"] === 'error') {
        errors.push(c.searchText + ' could not be found \r\n');
      }
      else if (cropBorders && card.image_uris?.border_crop) {
        c.images = card.image_uris.border_crop;
      }
      else {
        c.images = (card.card_faces) ? card.card_faces.map((cardFace) => {
          return cardFace.image_uris["large"]}) : [card.image_uris["large"] ]
      }
    }));

    setFoundCards(cards);
    setDeckList(deckList);
    setErrors(errors);
    setIsLoading(false);
}

  return (
    <CardContext.Provider value={{
        deckList, setDeckList,
        foundCards, setFoundCards, findCards,
        errors, setErrors,
        prefersPromo, setPrefersPromo,
        prefersNonPromo, setPrefersNonPromo,
        prefersNewest, setPrefersNewest,
        cropBorders, setCropBorders,
        isLoading
        }}>
      {props.children}
    </CardContext.Provider>
  )
}